/* COMMON COLOURS */
/* dark app background #332d2d */
/* earth-i green rgb(80, 145, 39) */
/* light page background #f8f9fa */
/* dashboard containers olive  rgba(86, 88, 70) */

/* GENERAL */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f9fa;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#appContainer {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 100vh;
	max-width: 100vw;
	background-color: #332d2d;
}

.contentContainer {
	padding: 10px;
	background-color: #f8f9fa;
	min-height: 100vh;
}

#secondColumn {
	width: 100vw;
}

.Tooltip {
	background-color: 'orange';
}

.Tooltip {
	width: max-content;
	background-color: #444;
	color: white;
	font-size: 90%;
	padding: 4px 8px;
	border-radius: 4px;
}

.dashboard-card {
	box-shadow: 2px 2px 7px #d9d9d9;
	border-radius: 10px; /* Rounded corners */
}

.dashboard-card-header {
	background-color: #555555; /* Light background for header */
	font-weight: bold;
	font-size: 1.1rem;
	padding: 12px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: white;
	font-weight: normal;
	height: 25%;
}

.dashboard-card-body{
	background-color: #ffffff; /* White background for the body */
	padding: 20px;
	border-radius: 7px; /* Rounded corners */
	height: 75%
}

.capture-id-table td {
	border: none;
	padding: 5px;

}

.capture-id-table {
	width: 100%;
}


.dashboard-div {
	background-color: white;
	border-radius: 6px;
	display: inline-block;
	box-shadow: 2px 2px 7px #d9d9d9;
	width: 100%;
	height: 100%;
}

/* SIDENAV */
.topnav {
	overflow: hidden;
	background-color:#332d2d;
	position: relative;
	height: 100%;
}

.topnav #myLinks {
	display: none;
}

.topnav #myLinks2 {
	position: relative;
	bottom: 0;
	width: 100%;
	display: none;
}


.topnav a {
	color: white;
	padding: 14px 16px;
	text-decoration: none;
	display: block;
	font-size: 15px;
}

.topnav a.close-sidenav-icon {
	background: rgb(33, 37, 41);
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

.topnav a:hover {
	background-color: rgb(154, 154, 154) 3;
	color: white;
}

.active {
	background-color: #04aa6d;
	color: white;
}

.navbarContainer {
	/* overflow: hidden; */
	background-color:#332d2d;
	position: relative;
	width: 200px;
}

.navbarContainer #side-nav-links {
	display: none;
}

.nav a,
.accordian a {
	color: #6c757d;
	text-decoration: none;
}

.nav-pill a:hover,
.accordian a:hover {
	color: #6c757d;
	text-underline-offset: 4px;
	text-decoration: underline;
}

.navHover {
	color: rgb(96, 174, 48) !important;
}

.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link.disabled {
	pointer-events: none;
	cursor: default;
	color: #51575d;
}

.accordion-button:not(.collapsed)::after {
	color: white !important;
	background-image: none;
}



.sidenav-hr {
	margin-left: 7px;
	margin-right: 7px;
	margin-top: 5px;
	margin-bottom: 5px;
	border: none;
	border-top: 1px solid white;
}

.dashboard-stats-table td{
	border: none !important
}

#captureImageHover:hover {
	transform: scale(1.2)
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important; /* Change the last value to control opacity */
}

/* BUTTONS */
.noStyleButton {
	all: unset; /* Remove all inherited and default styles */
	display: inline; /* Set default display to inline for buttons */
	cursor: pointer; /* Add cursor pointer to indicate clickability */
	background: none; /* Remove background */
	border: none; /* Remove border */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margin */
	font: inherit; /* Inherit font from parent */
	color: inherit; /* Inherit text color from parent */
}

.backButton {
	max-height: 40px;
	width: 100px;
}

.interactButton {
	background-color: rgb(80, 145, 39) !important;
	color: white !important;
	/* max-height: 40px; */
	/* width: 100px; */
}

.interactButtonSecondary {
	background-color: #f8f9fa !important;
	border-color: rgb(98, 152, 64) !important;
	color: grey !important;
	/* max-height: 40px; */
	/* width: 100px; */
}

.interactButtonDisabled {
	background-color: rgb(80, 145, 39) !important;
	opacity: 0.5;
	color: white !important;
}

.interactButton:hover {
	background-color: rgb(101, 149, 71) !important;
	color: white !important;
}

.QCTypeButtons {
	border: none;
	background-color: #f8f9fa;
	width: 100%;
}

.QCTypeButtonsBtn:hover {
	background-color: #fbfbfb;
}

.interactButtonSecondary:hover {
	background-color: rgb(98, 152, 64) !important;
	color: white !important;
}

.btn {
	/* font-size: 15px !important; */
	/* height: 30px !important; */
	/* width: 130px !important; */
}

.truncate {
    white-space: nowrap;       /* Prevents content from wrapping to a new line */
    overflow: hidden;          /* Hides the overflowing content */
    text-overflow: ellipsis;   /* Displays '...' when content overflows */
    width: 300px;              /* Sets a fixed width for the cell */
    max-width: 300px;          /* Ensures the cell doesn't expand beyond this width */
    display: inline-block;     /* Ensures it works correctly inside table cells */
}

/* DASHBOARD PAGE */
/* QC PAGE */
.QCTypeCard:hover {
	background-color: #f8f9fadc;
}

.card {
	cursor: 'url' !important;
}

/* QCing */
.QCImage-map-general-div-smelters {
	position: relative;
	min-height: 400px;
	height: 100%;
	width: 100%;
}

.QCImage-leaflet-map-smelters {
	height: 100% !important;
}

.QCImage-map-general-div-stockpiles {
	position: relative;
	height: 400px;
	width: 100%;
}

.QCImage-leaflet-map-stockpiles {
	height: 400px !important;
}

.QCImage-map-general-div-aircraft {
	position: relative;
	height: 650px;
	width: 100%;
}

.QCImage-leaflet-map-aircraft {
	height: 650px !important;
}

.QCImage-map-absolute-div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.QCImage-leaflet-map {
	height: 100%;
	width: 100%;
	padding: 0px;
	margin: 5px;
	border-radius: 4px;
}




@media (min-width: 600px) {
	
}

@media (max-width: 600px) {
	
}

/* Small devices (portrait tablets and large phones, 600px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {
	
}

/* Medium devices (landscape tablets, 768px to 992px) */
@media (max-width: 768px) {
	#QCIng-info-row {
		font-size: 10px;
	}

	#QC-smelter-form {
		font-size: 12px;
	}

	

}

@media (min-width: 768px) {
	
	
}

@media (min-width: 768px) and (max-width: 992px) {
	#QCFormTable {
		font-size: 12px;
	}
}

@media (min-width: 992px) {
	
}

/* Large devices (desktops, 992px to 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	
}



.scroll-container {
	height: 250px;
	overflow-y: scroll;
	/* border: 1px solid #ccc; */
	padding: 8px;
	direction: rtl
}

/* WebKit browsers */
.scroll-container::-webkit-scrollbar {
	width: 10px;
}

.scroll-container::-webkit-scrollbar-track {
	background: rgba(98, 152, 64, 0.2) ;
	border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-thumb {
	background: rgba(98, 152, 64, 0.2) !important;
	border-radius: 5px !important;
	height: 50px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
	background: rgb(98, 152, 64) ;
}

/* Firefox */
.scroll-container {
	scrollbar-width: thin;
	scrollbar-color: rgb(98, 152, 64)  #f8f9fa;
	
}

/* Example content */
.content {
	height: 300px; /* Content height to enable scrolling */
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {
}

/* Medium devices (landscape tablets, 768px to 992px) */
@media (max-width: 768px) {
	.sidenav-hr {
		display: none;
	}

	.container-hr {
		display: none;
	}
}

@media (min-width: 768px) {
	.topnav a.close-sidenav-icon {
		display: none;
	}

	.navbarContainer #side-nav-links {
		display: block !important;
	}

	.topnav #myLinks2 {
		position: absolute;
		bottom: 0;
		width: 100%;
		display: none;
	}

	.tooltip {
		z-index: 99999 !important;
		position: absolute;
	}

	.progress-bar-container {
		width: 85%;
	}

	/* .dropdown-toggle:hover {
		font-size: 20px;
	} */

	.dropdown-toggle::after {
		border-top-color: white;
		border-width: 0.2em; /* Adjust this value for the default size */
	  }
	  
	  /* Increase arrow size on hover */
	  .dropdown-toggle:hover::after {
		border-top-color: white; /* Keep the same color or change it */
		border-width: 0.3em; /* Increase the border width for a larger arrow */
	  }

	.interactButton,
	.interactButtonSecondary,
	.btn-secondary {
		/* width: 150px; */
	}

	.contentContainer {
		padding: 14px;
	}

	.btn {
		/* width: 180px !important; */
	}
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 992px) {
	.progressBarText {
		font-size: 12px !important;
		margin: 0px;
	}

	.progress-bar-abs-div,
	.progress-bar-div-2 {
		height: 30px;
	}

	.progress {
		height: 28px !important;
	}

	.progress-bar-container {
		width: 92%;
	}

	.QCImage-map-general-div-stockpiles {
		height: 82vh;
	}

	.QCImage-leaflet-map-stockpiles {
		height: 82vh !important;
	}
}

/* Large devices (desktops, 992px to 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.contentContainer {
		padding: 20px;
	}
}

/* smelter form */
.activeCap {
	color: rgb(218, 165, 32) !important;
}

.invalidCap {
	color: rgb(169, 169, 169) !important;
}

.inactiveCap {
	color: rgb(65, 105, 225) !important;
}

.activeCapRow {
	background-color: rgb(218, 165, 32) !important;
}

.invalidCapRow {
	background-color: rgb(169, 169, 169) !important;
}

.inactiveCapRow {
	background-color: rgb(65, 105, 225) !important;
}

/* USER CAPTURES PAGE */

/* PROGRESS BAR */
.progress-bar-container {
	position: relative;
	width: 88%;
}

.progress {
	padding: 0px !important;
	max-width: 100%;
}

#QCprogressbar {
	background-color: rgba(80, 145, 39, 0.8) !important;
}

.progressBarText {
	font-size: 0px;
	margin: 0px;
}

.progress-bar-abs-div {
	position: absolute;
	width: 100%;
}

.progress-bar-div-2 {
	position: absolute;
	width: 100%;
}

/* TABLES */
/* capture table */

/* aircraft table */
#selectedAircraftTable td,
#autoQCCriteriaTable td {
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

#autoQCCriteriaTable {
	table-layout: fixed;
}

#autoQCCriteriaTable td {
	/* border: solid black 1px; */
	text-align: center;
	width: 20%
}

.captureTable {
	border-collapse: collapse;
	table-layout: fixed;
	padding: 5px;
	width: 100%
}

.captureTable th {
	font-weight: normal !important;
	padding: 10px;
	background-color: rgb(84, 88, 81);
	color: white !important;
	text-align: center;
}

.slider {
	-webkit-appearanfce: none;
	width: 100%;
	height: 15px;
	border-radius: 5px;  
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%; 
	background: #04AA6D;
	cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #04AA6D;
	cursor: pointer;
  }

.captureTable tr {
	background-color: white;
}

.captureTable td {
	padding: 8px;
	text-align: center;
}

.captureTable tr {
	border-bottom: solid 1px rgb(234, 234, 234);
}

.captureTable tbody tr:hover {
	background-color: #e9e9e9 !important;
}

.captureTable thead th:first-child {
	border-top-left-radius: 6px;
}

.captureTable thead th:last-child {
	border-top-right-radius: 6px;
}

.captureTable tbody tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}

.captureTable tbody tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}

.captureTable tbody tr:last-child {
	border: none;
}

.captureTableCurrentCapDot {
	height: 10px;
	width: 10px;
	background-color: green;
	border-radius: 50%;
	margin-right: 5px;
	animation: flash 1.35s infinite;
}

@keyframes flash {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
}

.qc-report-table th,
.qc-report-table td {
	background-color:  #f8f9fa !important;
	border: none
}

/* END */

.activeStockpile {
	color: rgb(175, 175, 175)
}

.activeStockpile:hover {
	color: white;
}

.highlightStockpile {
	color: white
}

.interact {
	color: rgb(98, 152, 64) !important;
}

.login-card a {
	color: #6c757d;
	text-decoration: none;
}

.login-card a:hover {
	color: #3c3e40;
	text-decoration: none;
}

hr {
	border-top: solid 2px;
}

.esa-card-logo {
	height: 50px;
}

.btn {
	border-radius: 3px !important;
	/* margin: 4px !important; */
}

.card {
	color: #676767;
}

.breadcrumb {
	font-size: 15px;
}

.gains-card-logo {
	height: 60px;
}

/* QC */

.captures-progress-circles {
	height: 15px;
	width: 15px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

/* OVERRIDING BOOTSTRAP */

.accordion-button {
	box-shadow: none !important;
	background-image: none !important;
}

.accordion-button.collapsed::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.form-check-input:checked {
	background-color: #332d2d !important;
	border-color: #332d2d !important;
	
}

/* .form-check-input:focus {
	outline: #f8f9fa !important;
    outline-offset: 0px !important;
	background-color: #f8f9fa !important;
} */

input[type='range']::-webkit-slider-thumb,
input[type='range']::-webkit-slider-thumb:focus,
input[type='range']::-webkit-slider-thumb:active {
	background: #332d2d;
	box-shadow: none;
}
input[type='range']::-moz-range-thumb,
input[type='range']::-moz-range-thumb:focus,
input[type='range']::-moz-range-thumb:active {
	background: #332d2d;
	box-shadow: none;
}
input[type='range']::-ms-thumb,
input[type='range']::-ms-thumb:focus,
input[type='range']::-ms-thumb:active {
	background: #332d2d;
	box-shadow: none;
}

.r-pill {
	border: 0;
	padding: 0;
	margin: 0;
}

/* radio groups need a legend */
.r-pill legend {
	display: block;
	font-size: inherit;
	font-weight: bold;
	margin-bottom: 0.5em;
	padding: 0;
	width: 100%;
}

/*
	Optional inner wrapper (<div>) to provide additional styling.
*/
.r-pill__group {
	background: #fafafa;
	border: 1px solid #083851;
	border-radius: 2em;
	display: inline-block;
	padding: 0.2em;
}

.r-pill__item {
	display: inline-block;
	position: relative;
}

.r-pill__input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border: 2px solid;
	height: 100%;
	left: 0;
	opacity: 0.00001;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.r-pill__item label {
	border-radius: 2em;
	border: 2px solid transparent;
	/* color: #083851; */
	display: block;
	padding: 0.2em 0.75em;
}

.activePill label {
	color: rgb(204, 147, 3);
}

.inactivePill label {
	color: rgb(28, 74, 212);
}

.invalidPill label {
	color: rgb(69, 69, 69);
}

.activePill input:hover ~ label,
.activePill label:hover {
	background: rgb(255, 237, 193);
	border-color: rgb(218, 165, 32);
}

.inactivePill input:hover ~ label,
.inactivePill label:hover {
	background: rgb(185, 202, 255);
	border-color: rgb(65, 105, 225);
}

.invalidPill input:hover ~ label,
.invalidPill label:hover {
	background: rgb(234, 234, 234);
	border-color: rgb(169, 169, 169);
}

.r-pill[disabled] .activePill input:checked + label:hover,
.activePill input:checked + label {
	background: rgb(218, 165, 32);
	color: #fff;
}

.r-pill[disabled] .inactivePill input:checked + label:hover,
.inactivePill input:checked + label {
	background: rgb(65, 105, 225);
	color: #fff;
}

.r-pill[disabled] .invalidPill input:checked + label:hover,
.invalidPill input:checked + label {
	background: rgb(169, 169, 169);
	color: #fff;
}

.r-pill__item label:after {
	border: 2px solid;
	border-color: rgba(0, 0, 0, 0);
	border-radius: 2em;
	bottom: 0;
	content: '';
	left: 0;
	pointer-events: none; /* 1 */
	position: absolute;
	right: 0;
	top: 0;
	transition: bottom 0.2s ease-in-out, border-color 0.2s ease-in-out,
		left 0.2s ease-in-out, right 0.2s ease-in-out, top 0.2s ease-in-out;
}

.r-pill__item input:focus ~ label:after {
	/* bottom: -0.25em;
	left: -0.25em;
	right: -0.25em;
	top: -0.25em; */
}

.activePill input:focus ~ label:after {
	border-color: rgb(218, 165, 32);
}

.inactivePill input:focus ~ label:after {
	border-color: rgb(65, 105, 225);
}

.invalidPill input:focus ~ label:after {
	border-color: rgb(169, 169, 169);
}

/**
 * Disabled
 */
.r-pill[disabled],
.r-pill__item input[disabled] + label {
	opacity: 0.5;
}

.r-pill__item input[disabled] ~ label,
.r-pill__item input[disabled] ~ label:hover,
.r-pill[disabled] label:hover {
	background: transparent;
	border-color: transparent;
	cursor: not-allowed;
}

/* QC  */

.pipelineVisualCircle {
	border-radius: 50%;
	border: 4px solid black;
	width: 25px;
	height: 25px;
	margin: 15px;
}

.pipelineVisualLine {
	flex: 1;
	height: 2px;
	background-color: black;
}

#QCFormTable {
	margin-bottom: 0px;
}

#sentinel-hub-icon:hover,
#autoQCRefreshIcon:hover{
	transform: scale(1.2)
}

#qcprogressmodal-select-new-cap:hover {
	text-decoration: none;
}

#qcprogressmodal-select-new-cap:active {
	color: red
}

#QCFormTable td {
	text-align: center;
}

/* .table td {
	padding: 0px;
} */

.jump-button {
	padding: 10px 20px;
	font-size: 16px;
	background-color: rgb(80, 145, 39) !important;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	animation: highlightBtn 3s ease-in-out infinite;
}

.table {
	padding: 0.25rem !important;
}

.slider {
	width: 100%;
	height: 25px;
	margin: 10px 0;
}

.thumb {
	height: 20px;
	width: 40px;
	background-color: #ddd;
	color: #000;
	text-align: center;
	line-height: 20px;
	border-radius: 10px;
	cursor: pointer;
}

.thumb:focus {
	outline: none;
}

.track {
	top: 0;
	bottom: 0;
	background: #ddd;
	border-radius: 999px;
}

.track-0 {
	background: #666;
	opacity: 0.5;
	height: 50px;
	border-radius: 0px;
}

.track-2 {
	background: #666;
	opacity: 0.5;
	height: 50px;
	border-radius: 0px;
}

.graphThumb {
	position: absolute;
	touch-action: none;
	z-index: 2;
	left: 1120.46px;
	background-color: white;
	border: 2px solid black;
	height: 50px;
	width: 10px;
	border-radius: 0px;
}

.leaflet-div-icon {
	background-color: #f4f4f4 !important;
	border: 1px solid #666 !important;
	border-radius: 50% !important;
	display: inline-block !important;
	height: 15px !important;
	width: 15px !important;
}

.leaflet-editing-icon {
	background-color: #f4f4f4 !important;
	border: 1px solid #666 !important;
	border-radius: 50% !important;
	display: inline-block !important;
	height: 15px !important;
	width: 15px !important;
}

/* STOCKPILES MAP BUTTONS */

/* "AliceBlue",    // #F0F8FF
	"Coral",        // #FF7F50
	"Crimson",      // #DC143C
	"DarkCyan",     // #008B8B
	"Gold",         // #FFD700
	"Indigo",       // #4B0082
	"LimeGreen",    // #32CD32
	"MediumOrchid", // #BA55D3
	"RoyalBlue",    // #4169E1
	"Tomato"        // #FF6347 */

.SPButtonStyle0 {
	background-color: #f0f8ff !important;
}

.SPButtonStyle1 {
	background-color: #ff7f50 !important;
}

.SPButtonStyle2 {
	background-color: #dc143c !important;
}

.highlight {
	background-color: 'red' !important;
}

/* .table-responsive {
	max-height: 300px;
} */

.stockpilesPilesTable {
	text-align: center;
}

.stockpilesPilesTable td,
.stockpilesPilesTable th {
	background-color: #f8f9fa !important;
}

.unstyled-button {
	/* Remove default button styles */
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: black;
	cursor: pointer;
	outline: none;

	/* Remove any default appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	/* Optional: Reset additional properties */
	text-decoration: none;
	display: inline;
}

.label-icon {
	color: black !important;
}

.table-bold {
	font-weight: bolder;
}

.custom-button-1 {
	background-color: white !important; /* Red */
}
.custom-button-2 {
	background-color: #33ff69 !important; /* Green */
}
.custom-button-3 {
	background-color: orange !important; /* Blue */
}
.custom-button-4 {
	background-color: red !important; /* Yellow */
}

.color-picker-button {
	z-index: 1001;
}

.easy-button-button .original-stockpiles {
	background-color: #007bff !important;
}

.leaflet-map-toggle-btns-on {
	color: white;

	border-radius: 3px;
	border: none;
	margin: 2px;
	opacity: 1 !important;
}

.leaflet-map-toggle-btns-off {
	color: white;

	border-radius: 3px;
	border: none;
	margin: 2px;
	opacity: 0.3 !important;
}

.endpoint-info-table-dashboard td {
	padding: 8px !important;
}

.dashboard-user-info td {
	padding: 5px !important;
}

.arrow-right {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid black;
}

.qc-process-flow-circle {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	/* border: solid 5px orange; */
	background-color: #006400;
}

.qc-process-flow-minor-circle {
	width: 40px;
	height: 40px;
	margin-top: 10px;
	border-radius: 50%;
	/* border: solid 5px orange; */
	background-color: #006400;
}

.qc-process-flow-arrow-line {
	border-top: solid 2px black;
	height: 4px;
	width: 100%;
}

.overlapping-row {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.icon-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: black;
	font-size: 0.5em;
	pointer-events: none;
}

.form-check-label-dashboard-qcinfo,
.form-check-label-dashboard-env {
	display: inline-block;
	padding: 3px 12px;
	border: 2px solid #000000;
	border-radius: 15px;
	cursor: pointer;
	user-select: none;
	color: #000000;
	background-color: #ffffff;
	transition: background-color 0.3s, color 0.3s;
	font-weight: bold;
	font-size: 12px;
}

.form-check-input-dashboard-qcinfo,
.form-check-input-dashboard-env {
	display: none;
}

.form-check-dashboard-qcinfo,
.form-check-dashboard-env {
	display: inline-block;
	margin-right: 10px;
}

/* .fetch-capture-form {
	direction: rtl
} */

.form-check-input-dashboard-qcinfo:checked
	+ .form-check-label-dashboard-qcinfo {
	background-color: rgba(80, 145, 39, 0.8);
	color: #ffffff;
	border-color: rgba(55, 101, 26, 0.8);
}

.form-check-input-dashboard-env:checked + .form-check-label-dashboard-env {
	background-color: rgba(28, 26, 139, 0.8);
	color: #ffffff;
	border-color: rgba(25, 51, 156, 0.8);
}

/* ========================
   QC Image
======================== */

.flashing-dot {
	width: 15px;
	height: 15px;
	background-color: green;
	border-radius: 50%;
	animation: flash 1s infinite alternate;
}

.not-flashing-dot {
	width: 15px;
	height: 15px;
	background-color: rgb(124, 124, 124);
	border-radius: 50%;
	
}

@keyframes flash {
	0% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.7;
	}
	75% {
		opacity: 0.2;
	}
	100% {
		opacity: 0;
	}
}

.animated-ellipsis {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 15px; /* Adjust as needed */
  }
  
  .animated-ellipsis span {
	animation: blink 1.5s infinite;
	margin: 0 2px;
  }
  
  .animated-ellipsis span:nth-child(2) {
	animation-delay: 0.2s;
  }
  
  .animated-ellipsis span:nth-child(3) {
	animation-delay: 0.4s;
  }
  
  @keyframes blink {
	0% {
	  opacity: 0;
	}
	50% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }


  .custom-pagination .page-item {
	
	color: black;

  }

  .bigger {
	width: 50px !important;
	padding: 3px;
  }

  .uploadToastNotificationsTable {
	width: 100%
  }

  .uploadToastNotificationsTable td {
	background-color: #333;
	color: white
  }

  .uploadToastNotificationsTable tr {
	border: #333
  }
  
  .custom-pagination .page-item .page-link {
	padding: 4px 8px;
	color: black;
	border: 1px solid grey;
  }
  
  .custom-pagination .page-item.active {
	background-color: rgb(208, 208, 208);
  }
  
  .custom-pagination .page-item.active .page-link {
	background-color: rgb(208, 208, 208);
	color: black;
  }

  .custom-pagination .page-item.active .page-link2 {
	background-color: rgb(208, 208, 208);
	color: black;
  }
  
  .custom-pagination .page-item.disabled {
	opacity: 0.6;
  }


  ::-webkit-scrollbar-track {
	background: #333;
  }
  
  ::-webkit-scrollbar-thumb {
	background: #666;
  }
  
  ::-webkit-scrollbar-thumb:hover {
	background: #999;
  }

  /* Width of the scrollbar */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track (the area where the scrollbar runs) */
  ::-webkit-scrollbar-track {
	background: #f1f1f1; 
	border-radius: 10px;
  }
  
  /* Handle (the draggable part) */
  ::-webkit-scrollbar-thumb {
	background: #888; 
	border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #555; 
  }